<template>
  <div class="col-lg-3">
    <side-nav
      :items="navigationItems"
    />
  </div>

  <div class="col-lg-9 mt-lg-0 mt-4">
    <project-summary
      v-model:visual-image="fields.visualImage"
      v-model:visual-image-data="fields.visualImageData"
      v-model:author-user-id="fields.createdByUserId"
      :title="fields.title"
      :enable-author-selection="isNewItem"
    />

    <form @submit.prevent="submit">
      <base-fieldset
        id="basic-info"
        title="Algemeen"
      >
        <basic-info
          v-model:title="fields.title"
          v-model:subtitle="fields.subTitle"
          :show-update="!isNewItem"
        />
      </base-fieldset>

      <base-fieldset
        id="user-list"
        title="Is dit besloten?"
      >
        <material-switch
          v-model="fields.isRestricted"
        >
          Besloten?
        </material-switch>

        <collapse-box 
          :is-open="fields.isRestricted"
        >
          <user-list
            ref="userListRef"
            :user-ids="fields.invitedUsers"
            :add-user-callback="addUsers"
            :remove-user-callback="removeUsers"
          />

          <group-list
            ref="groupListRef"
            :group-ids="fields.invitedGroups"
          />
        </collapse-box>

        <material-button
          v-if="!isNewItem"
          class="float-end mt-6 mb-0"
          color="dark"
          variant="gradient"
          size="sm"
        >
          Update gegevens
        </material-button>
      </base-fieldset>

      <div
        v-if="!isNewItem"
        id="delete"
        class="card mt-4"
      >
        <div class="card-body">
          <div class="d-flex align-items-center mb-sm-0 mb-4">
            <div class="w-50">
              <h5>Verwijderen</h5>
              <p class="text-sm mb-0">
                Wanneer iets verwijderd wordt, kan dit niet meer ongedaan gemaakt worden.
              </p>
            </div>
            <div class="w-50 text-end">
              <material-button
                color="danger"
                variant="gradient"
                class="mb-0 ms-2"
                @click.prevent="remove"
              >
                Delete
              </material-button>
            </div>
          </div>
        </div>
      </div>

      <material-button
        v-if="isNewItem"
        color="primary"
        variant="gradient"
        class="float-end mt-4 mb-0 mx-4"
      >
        Opslaan
      </material-button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { add, edit, get, remove } from '@/api/providers/projects';
import BaseFieldset from '@/components/Forms/BaseFieldset.vue';
import BasicInfo from '@/components/Projects/Edit/BasicInfo.vue';
import ProjectSummary from '../../components/Projects/Edit/ProjectSummary.vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialSwitch from '@/components/MaterialSwitch.vue';
import CollapseBox from '@/components/UI/CollapseBox.vue';
import SideNav from '@/components/UI/SideNav.vue';
import UserList from '@/components/Projects/Edit/UserList/UserList.vue'

import setRouteNameForModule from '@/helpers/route-name';
import { CreateItemLabel, EditItemLabel } from '@/helpers/labels';
import { createYesNoSweetAlert } from '../../helpers/sweetalert';

import { 
  addUsersToRestrictedProject,
  removeUsersFromRestrictedProject
} from '@/api/providers/projects';

import { 
  addGroupsToRestrictedProject, 
  removeGroupFromRestrictedProject
} from '@/api/providers/projects';

import GroupList from '@/components/Shared/GroupList/GroupList.vue';

export default {
  name: 'ProjectEdit',

  components: {
    BaseFieldset,
    BasicInfo,
    ProjectSummary,
    MaterialButton,
    MaterialSwitch,
    SideNav,
    UserList,
    GroupList,
    CollapseBox
  },

  data: () => ({
    fields: {
      title: '',
      subTitle: '',
      visualImage: '',
      visualImageData: '',
      createdByUserId: 0,
      isRestricted: false,
      invitedUsers: [],
      invitedGroups: []
    },
    loading: false,
  }),

  computed: {
    ...mapGetters('account', [
      'organizationId',
    ]),

    isNewItem() {
      return this.$route.params?.projectId === undefined;
    },

    navigationItems() {
      return [
        {
          href: '#summary',
          icon: 'image',
          name: 'Samenvatting',
        },
        {
          href: '#basic-info',
          icon: 'notes',
          name: 'Algemeen',
        }
      ];
    },

    submitData() {
      return {
        ...this.fields,
        createdByUserId: this.fields.createdByUserId > 0 ? this.fields.createdByUserId : null,
        organizationId: this.organizationId,
      };
    }
  },

  beforeMount() {
    setRouteNameForModule(
      'Projects',
      this.isNewItem 
        ? CreateItemLabel
        : EditItemLabel
    );
  },

  mounted() {
    this.loadProjectData();
  },

  methods: {
    ...mapActions('notification', {
      addNotification: 'add',
    }),

    async addProject() {
      try {
        const addedEntity = await add(this.submitData);
        await this.$refs.userListRef.submit(addedEntity.id);

        await addGroupsToRestrictedProject(
          this.$refs.groupListRef.addedGroupIds,
          addedEntity.id
        );

        this.handleSaveSuccess();
      }
      catch {
        this.handleSaveError();
      }
      this.loading = false;
    },

    async editProject() {
      try {
        const updatedEntity = await edit(this.submitData);
        await this.$refs.userListRef.submit(updatedEntity.id);

        await addGroupsToRestrictedProject(
          this.$refs.groupListRef.addedGroupIds,
          updatedEntity.id
        );

        for (const groupId of this.$refs.groupListRef.removedGroupIds) {
          await removeGroupFromRestrictedProject(
            groupId,
            updatedEntity.id
          );
        }
        this.handleSaveSuccess();
      }
      catch(error) {
        this.handleSaveError();
      }
      this.loading = false;
    },

    handleGetSuccess(data) {
      Object.entries(data).forEach(([key, value]) => {
        this.fields[key] = value;
      });
    },

    handleRemoveSuccess() {
      this.addNotification({
        description: `${this.fields.title} is verwijderd.`,
        icon: {
          component: 'hive',
        },
      });

      this.navigateToProjects();
    },

    handleSaveSuccess() {
      this.addNotification({
        description: `${this.fields.title} is opgeslagen.`,
        icon: {
          component: 'hive',
        },
      });

      this.navigateToProjects();
    },

    handleSaveError() {
      this.addNotification({
        description: `Er is een fout opgetreden tijdens het opslaan van ${this.fields.title}`,
        icon: {
          component: 'hive',
        },
      });
    },

    loadProjectData() {
      if (this.isNewItem) {
        return;
      }

      get(this.$route.params.projectId)
        .then(this.handleGetSuccess)
        .catch((error) => { if (typeof error.default === 'function') error.default() })
    },

    navigateToProjects() {
      this.$router.push({
        name: 'projects',
      });
    },

    remove() {
      createYesNoSweetAlert(
        `${this.fields.title} verwijderen?`,
        () => {
          remove(this.$route.params.projectId)
            .then(this.handleRemoveSuccess)
            .catch((error) => error.default());
        }
      );
    },

    async addUsers(projectId, userIds) {
      await addUsersToRestrictedProject(userIds, projectId)
    },

    async removeUsers(projectId, userIds) {
      await removeUsersFromRestrictedProject(userIds, projectId)
    },

    submit() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      
      if (this.isNewItem) {
        this.addProject();
      } else {
        this.editProject();
      }
    },
  },
};
</script>
